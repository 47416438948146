<template>
  <div class="kpi">
    <h3 class="kpi__headline">
      <slot name="header">Heute</slot>
    </h3>

    <div class="kpi__body">
      <div class="kpi__body-inner">{{ value.toFixed(1) }}</div>

      <div class="kpi__progress" v-if="percentage">
        <el-progress type="circle" :percentage="percentage" :width="60" color="#8e71c7"></el-progress>
      </div>
    </div>

    <footer class="kpi__footer">
      <slot name="footer">Stunden</slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'CardKPI',

  props: {
    percentage: {
      type: Number,
      required: false
    },

    trend: {
      type: Number,
      required: false
    },

    value: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.kpi {
  &__headline {
    font-size: 1.5rem;
    font-family: $font-headline;
    margin-top: 0;
    text-align: center;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
      text-align: center;
      font-family: $font-text;
      font-size: 3rem;
      font-weight: 700;

      @media screen and (min-width: $screen-md) {
        font-size: 4rem;
        margin-right: 2rem;
        margin-left: 2rem;
        text-align: center;
      }
    }
  }

  &__footer {
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
  }
}
</style>
